import { PlaceModel } from "../models/place"
import { PlaceAreaModel } from "../models/placeArea"
import { parseQueryParams } from "../utils"


const buildLoader = <T>(promise: Promise<T | undefined>, name: string): Promise<{ [k: string]: T }> => {
    return new Promise((resolve, reject) => {
        promise.then((promiseRes?: T) => {
            if (promiseRes) {
                const res: { [k: string]: T } = {}
                res[name] = promiseRes
                resolve(res)
            } else {
                reject('NotFoundError')
            }
        }).catch((err)=>{         
            reject(err)
        })
    })
}


export const placeLoader = async (props: any): Promise<{ place: PlaceModel, areas: PlaceAreaModel[], area?: PlaceAreaModel }> => {
    console.debug('placeLoader')
    const url: string = props.request.url
    const queryParams = parseQueryParams(url)
    const params = props.params
    const place = await PlaceModel.getById(params.placeId)
    if (!place) {
        return Promise.reject('NotFoundError')
    }
    let area
    if (queryParams['areaId']) {
        area = await PlaceAreaModel.getById(queryParams['areaId'])
        if (!area) {
            return Promise.reject('NotFoundError')
        }
    }
    const areas = await PlaceAreaModel.getAll({ placeId: place.id }, { sort: { field: 'position', direction: 'asc' } })
    return { place: place, areas: areas, area: area }
}

export const placesLoader = ({ params }: { params: any }): Promise<{ [places: string]: PlaceModel[] }> => {
    console.debug('placesLoader')
    const promise = PlaceModel.getAll({})
    return buildLoader<PlaceModel[]>(promise, 'places')
}

