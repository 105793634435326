import React, { useMemo, useState } from 'react';
import {
  MaterialReactTable,
  MRT_EditActionButtons,
  MRT_TableInstance,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { QueryClient, QueryClientProvider, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import { PlaceModel, PlaceStatus } from '../models/place';
import { Link, useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import MapIcon from '@mui/icons-material/Map';
import MapIcon from '@mui/icons-material/HighlightAlt';
import EdiIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditAreaIcon from '@mui/icons-material/Crop54';



import TouchableConfirm from './TouchableConfirm';



// Crear QueryClient
const queryClient = new QueryClient();

interface PlaceTableProps {
  initialPlaces?: PlaceModel[];
}

const PlaceTable: React.FC<PlaceTableProps> = ({ initialPlaces = [] }) => {

  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});

  // Definir columnas de la tabla
  const columns = useMemo<MRT_ColumnDef<PlaceModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Centro',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () => setValidationErrors({ ...validationErrors, name: undefined }),
        },
      },
      {
        accessorKey: 'ateneaUrl',
        header: 'ERP URL',
        muiEditTextFieldProps: {
          type: 'url',
          required: true,
          error: !!validationErrors?.ateneaUrl,
          helperText: validationErrors?.ateneaUrl,
          onFocus: () => setValidationErrors({ ...validationErrors, ateneaUrl: undefined }),
        },
      },
      {
        accessorKey: 'odooDbHost',
        header: 'Host BD',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.odooDbHost,
          helperText: validationErrors?.odooDbHost,
          onFocus: () => setValidationErrors({ ...validationErrors, odooDbHost: undefined }),
        },
      },
      {
        accessorKey: 'odooDbName',
        header: 'Nombre BD',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.odooDbName,
          helperText: validationErrors?.odooDbName,
          onFocus: () => setValidationErrors({ ...validationErrors, odooDbName: undefined }),
        },
      },
      {
        accessorKey: 'odooDbUserName',
        header: 'Usuario BD',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.odooDbUserName,
          helperText: validationErrors?.odooDbUserName,
          onFocus: () => setValidationErrors({ ...validationErrors, odooDbUserName: undefined }),
        },
      },
      {
        accessorKey: 'odooDbPassword',
        header: 'Password DB',
        Cell: ({ row }: any) => <span title={row.original.odooDbPassword}>****</span>,
        muiEditTextFieldProps: {
          type: 'password',
          required: true,
          error: !!validationErrors?.odooDbPassword,
          helperText: validationErrors?.odooDbPassword,
          onFocus: () => setValidationErrors({ ...validationErrors, odooDbPassword: undefined }),
        },
      },
      {
        accessorKey: 'odooMulticenterCode',
        header: "Cod. MultiCentro",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.odooMulticenterCode,
          helperText: validationErrors?.odooMulticenterCode,
          onFocus: () => setValidationErrors({ ...validationErrors, odooMulticenterCode: undefined }),
        },
      },
      // {
      //   accessorKey: 'callCenter',
      //   header: 'Call Center',
      //   Cell: ({ row }: any) => <span>{row.original.callCenter ? 'Sí' : 'No'}</span>, // Mostrar "Sí" o "No" en lugar de true/false
      //   editVariant: 'select', // Cambiar a un menú desplegable en el modo de edición,
      //   editSelectOptions: [
      //     { value: true, label: 'Sí' },
      //     { value: false, label: 'No' },
      //   ],
      //   muiEditTextFieldProps: {
      //     select: true,
      //     helperText: 'Seleccione "Sí" o "No"',
      //   },
      // },
      /* {
        accessorKey: 'status',
        header: 'Status',
        editVariant: 'select',
        editSelectOptions: Object.values(PlaceStatus),
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.status,
          helperText: validationErrors?.status,
        },
      }, */
    ],
    [validationErrors],
  );

  // CRUD Hooks
  const { mutateAsync: createPlace } = useCreatePlace();
  const { data: places = [], isLoading: isLoadingPlaces } = useGetPlaces();
  const { mutateAsync: updatePlace } = useUpdatePlace();
  const { mutateAsync: deletePlace } = useDeletePlace();
  const navigate = useNavigate();


  // CREATE Action
  const handleCreatePlace: MRT_TableOptions<PlaceModel>['onCreatingRowSave'] = async ({ values, table }) => {
    const errors = validatePlace(values);
    if (Object.values(errors).some((error) => error)) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    await createPlace(values);
    table.setCreatingRow(null);
  };

  // UPDATE Action
  const handleSavePlace: MRT_TableOptions<PlaceModel>['onEditingRowSave'] = async ({ values, table }) => {
    const errors = validatePlace(values);
    if (Object.values(errors).some((error) => error)) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    try {
      debugger
      // Obtén el ID del lugar que se está editando
      const editingRow = table.options.state.editingRow;
      const updatedPlace = { ...values, id: editingRow?.original.id }; // Usa `editingRow.original.id`
      await updatePlace(updatedPlace); // Actualiza el lugar usando tu hook
      queryClient.invalidateQueries({
        queryKey: ['places'],
      }) // Refresca la tabla
      table.setEditingRow(null); // Sale del modo de edición
    } catch (err) {
      console.error('Error al guardar el lugar:', err);
    }
  };


  const handleDeletePlace = (place: PlaceModel) => {
    deletePlace(place.id);
  };

  const navigateEditAreas = (place: PlaceModel) => {
    navigate(`/admin/places/${place.id}/areas`);
  }

  const MoreMenu = (props: { table: MRT_TableInstance<PlaceModel>, row: MRT_Row<PlaceModel> }) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const table: MRT_TableInstance<PlaceModel> = props.table
    const row: MRT_Row<PlaceModel> | undefined = props.row

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Button
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="text"
          color='inherit'
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >

          <MenuItem onClick={() => table.setEditingRow(row)}>
            <Button startIcon={<EdiIcon />}>
              Editar datos del centro
            </Button>
          </MenuItem>

          <MenuItem>
            <Link to={`${row.original.id}/areas`} style={{ textDecoration: 'none', color: 'inherit' }} color={'inherit'}>
              <Button startIcon={<EditAreaIcon />}>
                Editar Areas
              </Button>
            </Link>
          </MenuItem>


          <MenuItem>
            <Link to={`/frontend/${row.original.id}`} style={{ textDecoration: 'none', color: 'inherit' }} target='_blank' color={'inherit'}>
              <Button startIcon={<VisibilityIcon />}>
                Previsualizar plano
              </Button>
            </Link>
          </MenuItem>

          <Divider />

          <MenuItem>
            <TouchableConfirm message="Quieres eliminar este centro?" mode="destructive" onConfirm={() => { handleDeletePlace(row.original) }}>
              <Button color='error' startIcon={<DeleteIcon />}>
                Eliminar centro
              </Button>
            </TouchableConfirm>
          </MenuItem>


        </Menu>
      </div>
    );



  }

  const renderCreateRowDialogContent = ({ table, row, internalEditComponents }: { table: any, row: any, internalEditComponents: any }) => {
    return (
      <>
        <DialogTitle variant="h5">Crear centro</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    )
  }

  const renderEditRowDialogContent = ({ table, row, internalEditComponents }: { table: any, row: any, internalEditComponents: any }) => {
    return (
      <>
        <DialogTitle variant="h5">Editar centro</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    )
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={places}
      createDisplayMode="modal"
      editDisplayMode="modal"
      enableEditing
      getRowId={(row) => row.id}
      onCreatingRowSave={handleCreatePlace}
      onEditingRowSave={handleSavePlace}
      renderCreateRowDialogContent={renderCreateRowDialogContent}
      renderEditRowDialogContent={renderEditRowDialogContent}
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <MoreMenu table={table} row={row} />
          <Tooltip title="Editar plano">
            <Link to={`${row.original.id}/map-editor`} style={{ textDecoration: 'none' }}>
              <IconButton color="primary">
                <MapIcon />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      )}
      renderTopToolbarCustomActions={({ table }) => (
        <Button variant="contained" onClick={() => table.setCreatingRow(true)}>
          <AddIcon />
          Nuevo
        </Button>
      )}
      state={{
        isLoading: isLoadingPlaces,
        showAlertBanner: false,
        showProgressBars: false,
      }}
    />
  );
};

// CRUD Hooks

function useCreatePlace() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (place: Partial<PlaceModel>) => {
      const newPlace = new PlaceModel();
      Object.assign(newPlace, place);
      await newPlace.save();
      await newPlace.createDefaultArea();
      return newPlace
    },
    onSuccess: (newPlace) => {
      queryClient.setQueryData(['places'], (oldPlaces: PlaceModel[] | undefined) => {
        return [...(oldPlaces || []), newPlace]; // Añade el nuevo lugar al estado existente
      });
    },
  });
}

function useGetPlaces() {
  return useQuery<PlaceModel[]>({
    queryKey: ['places'],
    queryFn: async () => PlaceModel.getAll({}),
    refetchOnWindowFocus: false,
  });
}

function useUpdatePlace() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (place: Partial<PlaceModel>) => {
      if (!place.id) {
        throw new Error("El ID del lugar no está definido.");
      }
      const existingPlace = await PlaceModel.getById(place.id);
      if (existingPlace) {
        debugger
        Object.assign(existingPlace, place);
        return existingPlace.save();
      }
    },
    onSuccess: () => queryClient.invalidateQueries({
      queryKey: ['places'],
    }),
  });
}

function useDeletePlace() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const place = await PlaceModel.getById(id);
      if (place) {
        return place.destroy();
      }
    },
    onSuccess: () => queryClient.invalidateQueries({
      queryKey: ['places'],
    }),
  });
}

// Validation function
function validatePlace(place: Partial<PlaceModel>) {
  return {
    name: !place.name ? 'Nombre es obligatorio' : '',
    ateneaUrl: !place.ateneaUrl ? 'ERP URL es obligatorio' : '',
    odooDbHost: !place.odooDbHost ? 'Host BD es obligatorio' : '',
    odooDbName: !place.odooDbUserName ? 'Nombre BD es obligatorio' : '',
    odooDbUserName: !place.odooDbUserName ? 'Usuario BD es obligatorio' : '',
    odooDbPassword: !place.odooDbPassword ? 'Password BD es obligatorio' : '',
    odooMulticenterCode: !place.odooMulticenterCode ? 'Cod. MultiCentro es obligatorio' : '',
    // callCenter: !place.callCenter ? 'Gestión de Centro es requerido' : '',
  };
}

// Componente con QueryClientProvider
export const PlaceTableWithProvider: React.FC<{ initialPlaces?: PlaceModel[] }> = ({
  initialPlaces = [],
}) => (
  <QueryClientProvider client={queryClient}>
    <PlaceTable initialPlaces={initialPlaces} />
  </QueryClientProvider>
);
