import { useRouteError } from "react-router-dom";
import Parse from 'parse';


export interface ErrorPageProps {

}

const getErrorMessage = (error: any): string => {
  if (typeof error == 'string') {
    return error
  }
  return error.statusText || error.message || "Error"
}

export function ErrorPage() {
  const error: any = useRouteError();
  const errorMessage: string = getErrorMessage(error)

  const logout = (): Promise<void> => {
    Parse.User.logOut().catch(err => console.error('Parse logOut error:', err))
    localStorage.removeItem('Parsetoken')
    window.location.reload()
    return Promise.resolve()
  }

  if (typeof error == 'object' && 'code' in error) {
    switch (error.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        logout()
        break
    }
  }

  return (
    <div id="error-page" style={{ padding: '10px' }}>
      <h1>Oops!</h1>
      <p>Lo sentimos, se ha producido un error.</p>
      <p>
        Cod. error: <i>{errorMessage}</i>
      </p>
    </div>
  );
}