import {
  Navigate,
  Outlet
} from "react-router-dom";
import AuthProvider, { useAuth } from "../../providers/auth";
import { Button, ListItemButton, ListItemText, Menu, MenuItem, MenuList, Paper } from "@mui/material";
import React from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

import {
  Location,
  useLocation,
  useNavigate
} from "react-router-dom";
import { SnackBarProvider } from "../../providers/snackbar";
import DialogProvider from "../../providers/dialogs";
import AlertsProvider from "../../providers/alerts";
import { CurrentPlaceProvider } from "../../providers/currentPlace";
import { GlobalLoaderProvider } from "../../providers/globalLoader";
import "../../App.css";



export function AdminRootParent() {
  console.debug('AdminRoot')

  return <SnackBarProvider>
    <DialogProvider>
      <AlertsProvider>
        <AuthProvider>
          <CurrentPlaceProvider>
            <GlobalLoaderProvider>
              <div style={{ padding: 10 }}>
                <Outlet />
              </div>
            </GlobalLoaderProvider>
          </CurrentPlaceProvider>
        </AuthProvider>
      </AlertsProvider>
    </DialogProvider>
  </SnackBarProvider>
}

export function AdminRoot() {


  console.debug('AdminRoot')

  const auth = useAuth()
  const currentUser = auth.state?.user
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const location: Location = useLocation()
  const navigate = useNavigate();


  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    auth.logout()
  }


  React.useEffect(() => {
    if (location.pathname == '/admin') {
      // console.debug('navigate admin')
      navigate("/admin/places");
    }
  }, [location])

  if (!currentUser) {
    return <Navigate to={'/admin/login'} />
  }

  return (
    <div>
      <div style={{ textAlign: 'right', backgroundColor: 'rgba(200,200,200,.1)', padding: 10 }}>

        <Button
          id="demo-positioned-button"
          aria-controls={isMenuOpen ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? 'true' : undefined}
          onClick={handleMenuClick}
        >
          {currentUser.get('username')}
          <PersonIcon />
        </Button>

        <Paper sx={{ width: 320 }}>
          <Menu open={isMenuOpen} onClose={handleMenuClose} anchorEl={anchorEl}>
            <MenuList dense>
              <MenuItem>
                <ListItemButton onClick={logout}>
                  Cerrar sesion
                </ListItemButton>
              </MenuItem>
            </MenuList>
          </Menu>
        </Paper>


      </div>
      <Outlet />
    </div>
  )
}