import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export type AlertConfirmMode = "default" | "destructive"


export interface TouchableConfirmProps {
    children: React.ReactNode,
    title?: string,
    message: string,
    confirmLabel?: React.ReactNode,
    cancelLabel?:  React.ReactNode,
    dialogProps?: Partial<DialogProps>,
    onConfirm: () => void,
    onCancel?: () => void,
    mode?:AlertConfirmMode
}

export default function TouchableConfirm(props: TouchableConfirmProps) {
    const [open, setOpen] = React.useState(false);
    const title = 'title' in props ? props.title : 'Confirmar'
    const mode = props.mode || 'default'
    const cancelLabel = props.cancelLabel || 'Cancelar'
    const message = props.message
    const { dialogProps } = props;


    let confirmLabel: React.ReactNode =  props.confirmLabel

    let confirmButtonColor:'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'|undefined
    switch(mode){
        case 'destructive':
            confirmButtonColor='error'
        
            if(!confirmLabel){
                confirmLabel='Eliminar'
            }
            break        
    }

    if(!confirmLabel){
        confirmLabel='Aceptar'
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setOpen(false);
        props.onConfirm()
    };

    const handleCancel = () => {
        setOpen(false);
        if (props.onCancel) {
            props.onCancel()
        }
    };
 

    return (
        <React.Fragment>

            <div onClick={handleClickOpen}>
                {props.children}
            </div>

            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                {...dialogProps}
            >

                {title &&
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                }


                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCancel} >{cancelLabel}</Button>
                    <Button onClick={handleConfirm} color={confirmButtonColor} >
                        {confirmLabel}
                    </Button>
                </DialogActions>

            </Dialog>


        </React.Fragment>
    );
}
