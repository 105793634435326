import React, { ReactNode } from "react";
import Parse from 'parse';
import { Backdrop, Box, Breadcrumbs, Button, Divider, FormControl, Grid, IconButton, InputLabel, ListItemIcon, Menu, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from "@mui/material";
import {
  Link,
  useLoaderData,
  useSearchParams,
  useLocation,
  useNavigate
} from "react-router-dom";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EdiIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import ImportIcon from '@mui/icons-material/InsertPhoto';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SyncIcon from '@mui/icons-material/Refresh';
import EditAreaIcon from '@mui/icons-material/Crop54';


import MoreVertIcon from '@mui/icons-material/MoreVert';
import MuiLink from '@mui/material/Link';



import { PlaceModel } from "../../models/place";
import { ViewMode, MapFeatureCollection, Dimensions } from "../../constants";
import { FileUploader } from "../../components/FileUploader";
import { copyToClipBoard, fileToBase64, getBounds, urlToBase64 } from "../../utils";
import { Feature, Polygon as GeojsonPolygon } from "geojson"
import GeomanWrapper, { GeomanHandler } from '../../components/Geoman'
import { SpaceModel } from "../../models/space";
import { BoxesList, BoxListItemAction } from "../../components/BoxesList";
import { useSnackBar } from "../../providers/snackbar";
import { MapOptions } from "leaflet";
import { AlertContextProps, useAlerts } from "../../providers/alerts";
import { useGlobalLoader } from "../../providers/globalLoader";
import { ImportGeoJsonDialog } from "../../dialogs/ImportGeoJsonDialog";
import { useDialog } from "../../providers/dialogs";
import * as L from 'leaflet'
import { FeatureItemModel, GetFeatureItemQueryParams } from "../../models/featureItem";
import { SVGPreviewDialog } from "../../dialogs/SVGPreviewDialog";
import { PlaceAreaModel } from "../../models/placeArea";
import { ImageOverlay, MapContainer, MapContainerProps } from "react-leaflet";
import TouchableConfirm from "../../components/TouchableConfirm";

const BASE_API_URL = process.env.REACT_APP_PARSE_BASE_API_URL
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL

let SELECTED_BOX: SpaceModel | null = null

function download(url: string, filename: string) {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
}

interface ItemPopupComponentProps {
  feature: Feature<GeojsonPolygon>
}

const ItemPopupComponent = (props: ItemPopupComponentProps): ReactNode => {
  const id = props.feature.id
  return (
    <Grid container>
      <Grid xs={6} item style={{ border: '1px red solid' }}>
        left {id}
      </Grid>
      <Grid item xs={6} style={{ border: '1px red solid' }}>
        right
      </Grid>
    </Grid>
  )
}



export default function PlaceDetailsPage() {

  const emptyGeoJson: MapFeatureCollection = { type: 'FeatureCollection', features: [] }


  const routeData: any = useLoaderData();
  // const location: any = useLocation();


  const defaultMapContainerOptions: MapContainerProps = { zoom: 1, center: [0, 0], minZoom: 1, maxZoom: 1, crs: L.CRS.Simple }
  const place: PlaceModel = routeData['place']

  const [viewMode, setViewMode] = React.useState<ViewMode>(ViewMode.CONTENT)
  const [geojson, setGeojson] = React.useState<MapFeatureCollection>(emptyGeoJson)
  const [boxes, setBoxes] = React.useState<SpaceModel[]>([])
  const [featureItems, setFeatureItems] = React.useState<FeatureItemModel[]>([])
  const [isModified, setIsModified] = React.useState<boolean>(false)


  const [mapContainerOptions, setMapContainerOptions] = React.useState<MapOptions>(defaultMapContainerOptions)
  const snackbar = useSnackBar()
  const alerts: AlertContextProps = useAlerts();
  const globalLoader = useGlobalLoader();
  const dialogsProvider = useDialog();
  const mapRef = React.useRef<GeomanHandler>();
  const [selectedBox, setSelectedBox] = React.useState<SpaceModel | null>(null)

  const defaultArea: PlaceAreaModel = routeData['area'] || routeData['areas'][0]
  const [areas, setAreas] = React.useState<PlaceAreaModel[]>(routeData['areas'])
  const [selectedArea, setSelectedArea] = React.useState<PlaceAreaModel>(defaultArea)
  const [mapFileUrl, setMapFileUrl] = React.useState<string | undefined>()
  const [mapFileDims, setMapFileDims] = React.useState<Dimensions>()
  const [ready, setReady] = React.useState<boolean>(false)
  const [imageOverLayBounds, setImageOverLayBounds] = React.useState<L.LatLngBoundsExpression>()
  const [searchParams, setSearchParams] = useSearchParams();
  const [frontendUrl, setFrontendUrl] = React.useState<string>('')
  const navigate = useNavigate();
  const syncAteneaMessage = "Se sincronizaran el estado de los boxes con atenea.\nAdemas se crearan o eliminaran boxes si es necesario.\nAl final quedaran los boxes tal y como estan en atenea."


  React.useEffect(() => {
    if (place) {
      init()
    }
  }, [place])

  React.useEffect(() => {
    SELECTED_BOX = selectedBox
    return () => {
      SELECTED_BOX = null
    }
  }, [selectedBox])

  React.useEffect(() => {
    // console.debug('mapFileUrl changed:',mapFileUrl)
    if (mapFileUrl && mapFileDims) {
      const bounds = getBounds(mapFileDims.width, mapFileDims.height);
      console.debug('bounds:', bounds)
      setImageOverLayBounds(bounds)
    } else {
      setImageOverLayBounds(undefined)
    }
  }, [mapFileUrl, mapFileDims])

  const handleUpload = async (file: File) => {
    const parseFile = new Parse.File(file.name, file);
    await selectedArea.setMapFile(parseFile)
    window.location.reload()
    // const fileBase64 = await fileToBase64(file)
    // setMapFileUrl(fileBase64)
  };

  const onMapFileChange = (file: File | File[]) => {
    handleUpload(file as File)
  }

  const loadBoxes = (): Promise<any> => {
    console.debug('loadBoxes')
    const onSuccess = (res: SpaceModel[]) => {
      console.debug('loadBoxes success', res.length)
      setBoxes(res)
    }
    const onError = (err: any) => {
      console.debug('loadBoxes error', err)
      snackbar.show({ message: `Error al cargar los boxes: ${err}` })
    }
    const onComplete = () => {
      setViewMode(ViewMode.CONTENT)
    }
    setViewMode(ViewMode.LOADING)
    return SpaceModel.getAll({ placeId: place.id,limit:1000000 }).then(onSuccess, onError).finally(onComplete)
  }

  const loadFeatureItems = (): Promise<any> => {
    console.debug('loadFeatureItems')
    const onSuccess = (res: FeatureItemModel[]) => {
      console.debug('loadFeatureItems success', res.length)
      setFeatureItems(res)
    }
    const onError = (err: any) => {
      console.debug('loadFeatureItems error', err)
      snackbar.show({ message: `Error al cargar los features: ${err}` })
    }
    const onComplete = () => {
      setViewMode(ViewMode.CONTENT)
    }
    setViewMode(ViewMode.LOADING)
    const cloudParams: GetFeatureItemQueryParams = {}
    if (selectedArea) {
      console.debug('selectedArea:', selectedArea.name)
      cloudParams.areaId = selectedArea.id
    }
    return FeatureItemModel.getAll(cloudParams).then(onSuccess, onError).finally(onComplete)
  }

  const syncBoxes = () => {
    console.debug('syncBoxes')
    const onSuccess = (res: any) => {
      console.debug('syncBoxes success', res)
      loadBoxes()
    }
    const onError = (err: any) => {
      console.debug('syncBoxes error', err)
      snackbar.show({ message: `Error al sincronizar los boxes: ${err}` })
    }
    const onComplete = () => {
      setViewMode(ViewMode.CONTENT)
    }
    setViewMode(ViewMode.LOADING)
    Parse.Cloud.run("atenea:syncBoxes", { placeId: place.id }).then(onSuccess, onError).finally(onComplete)
  }

  const loadGeoJson = (selectedArea: PlaceAreaModel): Promise<any> => {
    console.debug("loadGeoJson")
    const onSuccess = (res: MapFeatureCollection) => {
      console.debug("loadGeoJson success")
      setGeojson(res)

    }
    const onError = (err: any) => {
      console.debug("loadGeoJson error:", err)
      snackbar.showError({ message: `Se ha producido un error:${err}` })
    }
    const onComplete = () => {
      globalLoader.hide()
    }
    globalLoader.show()
    return Parse.Cloud.run('placeArea:getGeoJson', { areaId: selectedArea.id }).then(onSuccess, onError).finally(onComplete)
  }

  const reloadGeoJson = () => {
    setReady(false)
    loadGeoJson(selectedArea)
    setTimeout(() => {
      setReady(true)
    }, 0);
  }

  const reloadBoxes = () => {
    setBoxes([])
    setTimeout(() => {
      loadBoxes()
    }, 1000);
  }

  const init = async () => {
    console.debug("init")
    setMapContainerOptions({ ...defaultMapContainerOptions, ...selectedArea?.mapContainerOptions })
    const mapUrl = `${BASE_API_URL}/mapplic/places/${place.id}/map_data.json`
    setFrontendUrl(`${FRONTEND_URL}/?map=${encodeURI(mapUrl)}`)

    await loadBoxes()
    await loadFeatureItems()
    if (selectedArea) {
      await loadGeoJson(selectedArea)
      if (selectedArea.mapFile) {
        const fileUrl: string = selectedArea.mapFile.url()
        const mapFileUrl = await urlToBase64(fileUrl)
        setMapFileUrl(mapFileUrl)
        const mapFileWidth: number = selectedArea.get("mapFileWidth")
        const mapFileHeight: number = selectedArea.get("mapFileHeight")
        if (mapFileWidth && mapFileHeight) {
          setMapFileDims({ width: mapFileWidth, height: mapFileHeight })
        }
      }
    }
    setReady(true)
  }


  const openImportGeoJsonDialog = () => {
    const el = <ImportGeoJsonDialog />
    const onClose = (json?: MapFeatureCollection) => {
      if (json) {
        setGeojson(json)
      }
    }
    dialogsProvider.open({ children: el, onClose: onClose })
  }

  const copyGeoJsonToClipBoard = () => {
    if (!geojson) {
      alerts.showError({ message: 'No hay nada para exportar.' })
      return
    }
    copyToClipBoard(JSON.stringify(geojson))
    snackbar.show({ message: `Texto copiado al portapapeles`, autoHideDuration: 3000 })
  }

  // const exportMaplicJson = () => {
  //   if (!geojson) {
  //     alerts.showError({ message: 'No hay nada para exportar.' })
  //     return
  //   }
  //   snackbar.showWarning({ message: `#Todo`, autoHideDuration: 3000 })
  // }

  // const previewSVG = () => {
  //   console.debug("previewSVG")
  //   const onSuccess = (res: string) => {
  //     const el = <SVGPreviewDialog svg={res} />
  //     dialogsProvider.open({ children: el })
  //   }
  //   const onError = (err: any) => {
  //     console.debug("previewSVG error:", err)
  //     snackbar.showError({ message: `Se ha producido un error:${err}` })
  //   }
  //   const onComplete = () => {
  //     globalLoader.hide()
  //   }
  //   globalLoader.show()
  //   Parse.Cloud.run("featureItem:svgExport", { placeId: place.id }).then(onSuccess, onError).finally(onComplete)
  // }


  const clearMap = () => {
    mapRef.current?.clearMap()
    setGeojson({ ...emptyGeoJson })
    setIsModified(true)
  }

  const saveMap = (json?: MapFeatureCollection) => {
    // const geojson: MapFeatureCollection | undefined = mapRef.current?.getGeojson()//mapRef.current?.geojson//mapRef.current?.getGeojson()
    // console.debug('saveMap:', geojson)
    // return
    if (!geojson) {
      snackbar.showError({ message: "No hay datos para guardar" })
      return
    }
    const onSuccess = (res: any) => {
      console.debug("saveMap success")
      snackbar.showSuccess({ message: 'Datos guardados' })
      // setGeojson({...geojson})      
      setIsModified(false)
      // reloadGeoJson()
      // reloadBoxes()
    }
    const onError = (err: any) => {
      console.debug("saveMap error:", err)
      snackbar.showError({ message: `Se ha producido un error:${err}` })
    }
    const onComplete = () => {
      globalLoader.hide()
    }
    globalLoader.show()
    return Parse.Cloud.run("featureItem:createByGeoJson", { areaId: selectedArea.id, geojson: (json || geojson) }).then(onSuccess, onError).finally(onComplete)
    // place.save({ geojson: geojson }).then(onSuccess, onError).finally(onComplete)
  }

  const onBoxItemClick = (box: SpaceModel, action: BoxListItemAction) => {
    // console.debug('onBoxItemClick:',action)
    if (action == 'assign') {
      const map: any = mapRef.current?.map
      console.debug('map:', map)
      console.debug('pm:', map.pm)

      map.pm.Draw.disable();

      setSelectedBox(box)
    } else if (action == 'unassign') {
      unAssignSpace(box)
    }
  }

  const cancelBoxSelection = () => {
    setSelectedBox(null)
  }

  const assignSpace = async (featureUUID: string, space: SpaceModel): Promise<FeatureItemModel> => {
    console.debug(`Asignar box ${space.name} a uuid ${featureUUID}`)
    const model = await FeatureItemModel.getById(featureUUID)
    if (!model) {
      return Promise.reject("No se ha encontrado el elemento")
    }
    console.debug('model:', model)
    await model.save({ space: space })
    loadFeatureItems()
    const data: MapFeatureCollection | undefined = mapRef.current?.geojson
    if (!data) {
      return Promise.reject('Error')
    }
    const feature: any = data.features.find(i => i.properties?.uuid == featureUUID)
    if (feature) {
      feature.properties.name = space.name
      feature.properties.interactive = "1"
      setGeojson({ ...geojson })
    }
    setSelectedBox(null)
    mapRef.current?.refreshMap()
    return model
  }

  const unAssignSpace = async (space: SpaceModel): Promise<any> => {
    const item = await FeatureItemModel.getBySpace(space)
    if (!item) {
      snackbar.showError({ message: 'Elemento no encontrado' })
      return
    }
    await item.save({ space: null })
    loadFeatureItems()
    const feature: any = geojson.features.find(i => i.properties?.uuid == item.id)
    if (feature) {
      feature.properties.name = null
      feature.properties.interactive = "0"
      setGeojson({ ...geojson })
    }
  }


  const saveFeatureToDb = (feature: any): Promise<FeatureItemModel> => {
    // console.debug('saveFeatureToDb:',feature)
    return FeatureItemModel.createByGeojson(selectedArea, feature)
  }

  const onMapItemClick = async (ev: any) => {
    if (!SELECTED_BOX) {
      return
    }
    if (isModified) {
      alert('Hay cambios sin guardar')
      // await saveMap()
      return
    }
    const layer: L.Layer = ev.layer
    const feature: Feature<any> = (layer as any).toGeoJSON()//(layer as any).feature
    console.debug('feature:', feature)
    const uuid: string | undefined = feature?.properties?.uuid
    if (SELECTED_BOX) {
      if (uuid) {
        assignSpace(uuid, SELECTED_BOX)
      } else {
        const featureItem: FeatureItemModel = await saveFeatureToDb(feature)
        setFeatureItems([...featureItems, featureItem])
        await assignSpace(featureItem.id, SELECTED_BOX)
        reloadGeoJson()
      }
      setSelectedBox(null)
    }
  }


  const onMapItemRemove = (ev: any) => {
    console.debug('onMapItemRemove:', ev)
    setIsModified(true)
    const feature: Feature | undefined = ev.layer?.feature
    if (feature && feature.properties?.uuid) {
      const uuid: string = feature.properties.uuid
      const featureItem: FeatureItemModel | undefined = featureItems.find(f => f.id == uuid)
      // console.debug('featureItem:',featureItem)
      if (featureItem) {
        const idx = featureItems.indexOf(featureItem)
        featureItems.splice(idx, 1)
        setFeatureItems([...featureItems])
      }
    }
  }


  const onGeoJsonChange = (ev: any) => {
    setIsModified(true)
    setGeojson({ ...ev })
    // console.debug('onGeoJsonChange',ev)
  }


  React.useEffect(() => {
    const areaIdParam = searchParams.get('areaId')
    if (areaIdParam && selectedArea && (areaIdParam != selectedArea.id)) {
      // setTimeout(() => {
      //   window.location.reload()  
      // }, 5000);
      window.location.reload()
    }
  }, [searchParams, selectedArea])

  const onAreaChange = (event: SelectChangeEvent) => {
    const areaId: string = event.target.value
    console.debug('area change:', areaId)
    setSearchParams({ areaId: areaId })   
  }

  const AreaMoreMenu = () => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    //onClick={() => { navigateEditAreas() }}

    return (
      <div>
        <Button
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="text"
          style={{ color: 'initial' }}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem>
            <Button startIcon={<EditAreaIcon />} variant="text" href={`/admin/places/${place.id}/areas`} > Editar areas</Button>
          </MenuItem>

          <Divider />

          <MenuItem>
            {!selectedArea && <Button startIcon={<VisibilityIcon />} href={'/frontend/' + place.id} variant="text" target="_blank" >Previsualizar</Button>}
            {selectedArea && <Button startIcon={<VisibilityIcon />} href={'/frontend/' + place.id + '?areaId=' + selectedArea.id} variant="text" target="_blank" >Previsualizar</Button>}
          </MenuItem>

          <Divider />

          <MenuItem>
            <TouchableConfirm title="Sincronizar con atenea" message={syncAteneaMessage} confirmLabel="Sincronizar" onConfirm={syncBoxes}>
              <Button startIcon={<SyncIcon />} variant="text" >Sincronizar con atenea</Button>
            </TouchableConfirm>
          </MenuItem>

          <Divider />
          <MenuItem>
            <Button color="error" startIcon={<ClearIcon />}  variant="text" onClick={clearMap}>Borrar todo</Button>
          </MenuItem>

        </Menu>
      </div >
    );
  }



  return (
    <Grid container direction={'column'} style={{ height: '90vh' }} border={'0px red solid'}>

      {selectedBox &&
        <div>
          <div style={{ top: 0, left: 0, width: "100%", height: "100%", position: 'fixed', zIndex: 101, backgroundColor: 'rgba(100,100,100,0.5)' }}>

          </div>
          <div style={{ top: 0, left: 0, width: "100%", position: 'fixed', textAlign: 'center', zIndex: 102, padding: 5, backgroundColor: 'white' }}>
            <h4 style={{ marginBottom: 5 }}>Asignar box ({selectedBox.name})</h4>
            <div style={{}}>
              <Button variant="contained" onClick={cancelBoxSelection}>Cancelar</Button>
            </div>
          </div>
        </div>
      }


      <Grid item xs >
        <Grid container spacing={1} style={{ height: '100%' }} >
          <Grid item xs={8} lg={9}>


            <Grid item xs='auto' padding={1}>

              <Grid container spacing={2} alignItems={'center'}>

                <Grid item xs >
                  <Breadcrumbs aria-label="breadcrumb">
                    <MuiLink underline="hover" color="inherit" href="/admin">
                      Inicio
                    </MuiLink>
                    <Typography sx={{ color: 'text.primary' }}>{place.name}</Typography>
                  </Breadcrumbs>
                </Grid>


                <Grid item >

                </Grid>

              </Grid>

              <Grid container alignItems={'center'} marginTop={1} >

                <Grid item xs style={{}}>

                  <Grid container alignItems={'center'} spacing={1} >

                    <Grid item style={{}}>
                      <FileUploader accept="image/png,image/jpeg" onFileChange={onMapFileChange}>
                        <Tooltip title="Importar plano">
                          <Button size="small" startIcon={<ImportIcon />} fullWidth variant="contained">Importar plano</Button>
                        </Tooltip>
                      </FileUploader>
                    </Grid>

                    <Grid item style={{}}>
                      <Tooltip title="Guardar">
                        <Button size="small" startIcon={<SaveIcon />} fullWidth variant="contained" onClick={() => saveMap()} disabled={!isModified}  >Guardar</Button>
                      </Tooltip>
                    </Grid>

                  </Grid>

                </Grid>

                <Grid item>

                  <Grid container spacing={1} alignItems={'center'}>

                    <Grid item >
                      <FormControl variant="standard" fullWidth>
                        <Select
                          value={selectedArea?.id}
                          label="Area"
                          onChange={onAreaChange}
                        >
                          {areas &&
                            areas.map((area) => (
                              <MenuItem key={area.id} value={area.id}>{area.name}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>



                    <Grid item >
                      <AreaMoreMenu />
                    </Grid>

                  </Grid>
                </Grid>


              </Grid>

            </Grid>

            {mapFileUrl && geojson &&
              <div className="map-container"  >


                <MapContainer zoomSnap={0.25} zoomDelta={0.25} crs={L.CRS.Simple} >
                  {mapFileUrl && imageOverLayBounds &&
                    <ImageOverlay
                      url={mapFileUrl}
                      bounds={imageOverLayBounds}
                      opacity={1}
                      zIndex={10}
                      className='image-overlay'
                    />
                  }
                  <GeomanWrapper
                    geojson={geojson}
                    mapOptions={mapContainerOptions}
                    mapFileUrl={mapFileUrl}
                    mapFileDimensions={mapFileDims}
                    onChange={onGeoJsonChange}
                    onMapItemRemove={onMapItemRemove}
                    ref={mapRef}
                    onMapItemClick={onMapItemClick} />
                </MapContainer>
              </div>
            }
          </Grid>

          <Grid item xs={4} lg={3}>
            <Grid container direction={"column"} spacing={2} style={{ height: '100%', width: '100%' }} border={'0px red solid'} padding={1} >
              <Grid item xs >
                <Grid container direction={"column"} style={{ height: '100%' }} >
                  <Grid item xs={'auto'} style={{}}>
                    <h3 style={{ margin: 5 }}>Boxes</h3>
                  </Grid>
                  <Grid item xs style={{ overflowY: 'auto' }}>
                    {boxes && boxes.length > 0 &&
                      <BoxesList items={boxes} onItemClick={onBoxItemClick} activeItemId={selectedBox?.id} featureItems={featureItems} />
                    }
                    {boxes && boxes.length == 0 &&
                      <Button variant="contained" onClick={syncBoxes}>Cargar boxes</Button>
                    }
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

        </Grid>

      </Grid>




    </Grid>
  )
}

