import React, { useState } from "react";
import { Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import { SpaceModel } from "../models/space";
import './Mapplic.css';
import { AteneaBoxStatus } from "../constants";
import { boxColors } from "./GeomanFront";

interface BoxListSearchProps {
    boxes: SpaceModel[]; // Lista de boxes basada en SpaceModel
    activeItemId?: string; // ID del box activo
    onBoxSelect: (box: SpaceModel) => void; // Callback para seleccionar un box
}

const BoxListSearchFront: React.FC<BoxListSearchProps> = ({
    boxes,
    activeItemId,
    onBoxSelect,
}) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const activeItemRef = React.useRef<any>(null)


    /** Filtrar boxes según el término de búsqueda */
    const filteredBoxes = boxes.filter((box) =>
        box.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    /** Agrupar los boxes por estado */
    const availableBoxes: SpaceModel[] = []
    const rentedBoxes: SpaceModel[] = []
    const maintenanceBoxes: SpaceModel[] = []
    const reserverdBoxes: SpaceModel[] = []
    const unknownBoxes: SpaceModel[] = []



    for (let box of filteredBoxes) {
        switch (box.ateneaStatus) {
            case AteneaBoxStatus.AVAILABLE:
                availableBoxes.push(box)
                break
            case AteneaBoxStatus.RENTED:
                rentedBoxes.push(box)
                break
            case AteneaBoxStatus.MAINTENANCE:
                maintenanceBoxes.push(box)
                break
            case AteneaBoxStatus.RESERVED:
                reserverdBoxes.push(box)
                break
            default:
                unknownBoxes.push(box)
                break
        }
    }

    React.useEffect(() => {
        if (activeItemId) {
            console.debug('activeItemId:', activeItemId)
            console.debug('activeItemRef:', activeItemRef.current)
            if (activeItemRef.current) {
                activeItemRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            }
        } else {

        }
    }, [activeItemId, activeItemRef.current])



    /** Renderizar un grupo de boxes */
    const renderGroup = (
        title: string,
        boxes: SpaceModel[],
        color: string
    ) => {
        if (boxes.length === 0) return null; // No renderizar grupos vacíos

        return (
            <>
                <Typography
                    variant="subtitle1"
                    className="mapplic-dir-group-title"
                    style={{ color }}
                >
                    {title.toUpperCase()} ({boxes.length})
                </Typography>
                <List className="mapplic-dir-items">
                    {boxes.map((box) => (
                        <ListItem
                            key={box.id}
                            className={`mapplic-dir-item ${activeItemId === box.id ? "mapplic-active" : ""}`}
                            onClick={() => onBoxSelect(box)}
                            ref={activeItemId === box.id ? activeItemRef : null}
                        >
                            {/* Thumbnail */}
                            <div
                                style={{ backgroundColor: color }}
                                className={`mapplic-thumbnail ${box.isAvailable ? "available" : "rented"}`}
                            >
                                <span>{box.name.substring(0, 5)}</span>

                            </div>

                            {/* Información del box */}
                            <ListItemText
                                primary={box.name}
                                secondary={`${box.meters} m²`}
                                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                            />
                        </ListItem>
                    ))}
                </List>
            </>
        );
    };




    return (
        <div className="box-list">
            {/* Barra de búsqueda */}
            <div className="mapplic-search">

                <input
                    type="text"
                    placeholder="🔍 Buscar..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button onClick={() => setSearchTerm("")}>

                </button>
            </div>

            {/* Resultados de búsqueda */}
            {filteredBoxes.length > 0 ? (
                <>
                    {renderGroup("Disponible", availableBoxes, boxColors[AteneaBoxStatus.AVAILABLE].color)}
                    {renderGroup("Alquilado", rentedBoxes, boxColors[AteneaBoxStatus.RENTED].color)}
                    {renderGroup("Mantenimiento", maintenanceBoxes, boxColors[AteneaBoxStatus.MAINTENANCE].color)}
                    {renderGroup("Reservado", reserverdBoxes, boxColors[AteneaBoxStatus.RESERVED].color)},
                    {renderGroup("Otros", unknownBoxes, boxColors[AteneaBoxStatus.UNKNOWN].color)}
                </>
            ) : (
                <Typography variant="body1" style={{ color: "#999", textAlign: "center" }}>
                    No se encontraron resultados.
                </Typography>
            )}
        </div>
    );
};

export default BoxListSearchFront;
