import { Link, useLoaderData, useResolvedPath } from "react-router-dom";
import { PlaceModel } from "../../models/place";
import { PlaceAreaModel } from "../../models/placeArea";
import { Box, Breadcrumbs, Button, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiLink from '@mui/material/Link';
// import { arrayMove, SortableElementProps, SortableHandle, SortableElement, SortableContainer } from "react-sortable-hoc";
import React from "react";
import DragHandleIcon from '@mui/icons-material/DragIndicator';
import EdiIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useSnackBar } from "../../providers/snackbar";
import { EditAreaDialog } from "../../dialogs/EditAreaDialog";
import { useDialog } from "../../providers/dialogs";
import { CSS } from '@dnd-kit/utilities';

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import Parse from 'parse';
import { Padding } from "@mui/icons-material";
import { ConfirmationDialog } from "../../dialogs/ConfirmationDialog";
import TouchableConfirm from "../../components/TouchableConfirm";



export default function EditAreasPage() {
    const routeData: any = useLoaderData();
    const place: PlaceModel = routeData['place']
    const [areas, setAreas] = React.useState<PlaceAreaModel[]>(routeData['areas'])
    const snackbar = useSnackBar()
    const dialogsProvider = useDialog();
    const mapEditorPath: string = useResolvedPath('../map-editor', {}).pathname

    const SortableItem = (props: { item: PlaceAreaModel }) => {
        const item: PlaceAreaModel = props.item
        const {
            attributes,
            listeners,
            setNodeRef,
            setDraggableNodeRef,
            transform,
            transition,
        } = useSortable({ id: item.id });

        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
            borderBottom: '1px rgba(100,100,100,.5) solid',
            padding: 10
        };

        return (
            <Grid ref={setNodeRef} style={style} {...attributes} container alignItems={'center'} >
                <Grid item xs >
                    {item.name}
                </Grid>
                <Grid item>
                    <Tooltip title="Editar">
                        <Button onClick={() => { openEditAreaDialog(item) }}><EdiIcon /></Button>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <TouchableConfirm message="Quieres eliminar este area?" mode="destructive" onConfirm={() => { deleteArea(item) }}>
                        <Tooltip title="Eliminar">
                            <Button><DeleteIcon /></Button>
                        </Tooltip>
                    </TouchableConfirm>
                </Grid>
                <Grid item  >
                    <div style={{ display: ((areas.length > 1) ? undefined : 'none') }}>
                        <Tooltip title="Reordenar">
                            <Button ref={setDraggableNodeRef} {...listeners} color="inherit">
                                <DragHandleIcon style={{ cursor: 'move' }} />
                            </Button>
                        </Tooltip>
                    </div>
                </Grid>
            </Grid >
        )
    }


    const openEditAreaDialog = (area?: PlaceAreaModel) => {
        console.debug('openEditAreaDialog')
        const title = area ? 'Editar area' : 'Crear area'
        const value = area?.name
        const el = <EditAreaDialog place={place} title={title} value={value} />
        const onClose = (name?: string) => {
            if (name) {
                if (area) {
                    const onSuccess = (area: PlaceAreaModel) => {
                        // snackbar.showSuccess({ message: `Datos guardados correctamente` })
                        setAreas([...areas])
                    }
                    const onError = (err: any) => {
                        snackbar.showError({ message: `Se ha producido un error: ${err}` })
                    }
                    area.save({ name: name }).then(onSuccess, onError)
                } else {
                    const onSuccess = (newArea: PlaceAreaModel) => {
                        // snackbar.showSuccess({ message: `Datos guardados correctamente` })
                        areas.push(newArea)
                        setAreas([...areas])
                    }
                    const onError = (err: any) => {
                        snackbar.showError({ message: `Se ha producido un error: ${err}` })
                    }
                    const position = areas.length + 1
                    const newArea = new PlaceAreaModel({ place: place, name: name, position: position })
                    newArea.save(null).then(onSuccess, onError)
                }
            }
        }
        dialogsProvider.open({ children: el, onClose: onClose })
    }

    const deleteArea = (area: PlaceAreaModel) => {
        const onSuccess = (area: PlaceAreaModel) => {
            areas.splice(areas.indexOf(area), 1)
            setNewPositions(areas)
        }
        const onError = (err: any) => {
            snackbar.showError({ message: `Se ha producido un error: ${err}` })
        }
        return area?.destroy().then(onSuccess, onError)
    }


    const setNewPositions = (items: PlaceAreaModel[]) => {
        let idx: number = 1
        for (let item of items) {
            item.set('position', idx)
            idx++
        }
        const onSuccess = (res: PlaceAreaModel[]) => {
            setAreas([...res])
        }
        const onError = (err: any) => {
            snackbar.showError({ message: `Se ha producido un error:${err}` })
        }
        Parse.Object.saveAll(items).then(onSuccess, onError)
    }


    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldItem: PlaceAreaModel = areas.find(i => i.id == active.id) as PlaceAreaModel;
            const newItem: PlaceAreaModel = areas.find(i => i.id == over.id) as PlaceAreaModel;
            const oldIndex = areas.indexOf(oldItem);
            const newIndex = areas.indexOf(newItem);
            setNewPositions(arrayMove(areas, oldIndex, newIndex))
        }
    }


    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );


    return (
        <Grid container>
            <Breadcrumbs aria-label="breadcrumb">
                <MuiLink underline="hover" color="inherit" href="/admin">
                    Inicio
                </MuiLink>

                <MuiLink
                    underline="hover"
                    color="inherit"
                    href={mapEditorPath}
                >
                    {place.name}
                </MuiLink>


                <Typography sx={{ color: 'text.primary' }}>areas</Typography>
            </Breadcrumbs>
            <Grid container style={{ width: '100%', margin: 10, marginTop: 30, padding: "0px 50px" }} >

                <Box style={{ textAlign: 'left', width: "100%", marginBottom: 20 }}>
                    <Tooltip title="Crear area">
                        <Button variant="contained" onClick={() => openEditAreaDialog()}>
                            <AddIcon />
                            Nueva
                        </Button>
                    </Tooltip>
                </Box>

                <Grid item style={{ width: '100%' }} >
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext
                            items={areas}
                            strategy={verticalListSortingStrategy}
                        >
                            {areas.map(area => <SortableItem key={area.id} item={area} ></SortableItem>)}
                        </SortableContext>
                    </DndContext>
                </Grid>
            </Grid>
        </Grid>
    )

}
