import * as React from 'react'
import { FeatureGroup, Popup, Polygon, Tooltip, useMap } from 'react-leaflet'
// import * as L from 'leaflet'
import L, { Layer, Map } from 'leaflet'
import { getImageDimensions, getBounds, calculateCentroId } from '../utils'
import { Polygon as GeojsonPolygon, Feature, LineString } from "geojson"
import { AteneaBoxStatus, Dimensions, MapFeatureCollection } from '../constants'
import { Button } from '@mui/material'
import { SpaceModel } from '../models/space'
import { useEffect, useRef } from 'react'
import './Mapplic.css';
import { ArrowForward } from '@mui/icons-material'

interface ItemPopupComponentProps {
  feature: Feature<GeojsonPolygon>
}

export const boxColors: Record<AteneaBoxStatus, { color: string; fillColor: string; fillOpacity: number, 'stroke-width'?: number }> = {
  [AteneaBoxStatus.AVAILABLE]: { color: "green", fillColor: "green", fillOpacity: 0.7, 'stroke-width': 0 },
  [AteneaBoxStatus.RENTED]: { color: "red", fillColor: "red", fillOpacity: 0.7, 'stroke-width': 0 },
  [AteneaBoxStatus.MAINTENANCE]: { color: "orange", fillColor: "orange", fillOpacity: 0.7 },
  [AteneaBoxStatus.RESERVED]: { color: "blue", fillColor: "blue", fillOpacity: 0.7 },
  [AteneaBoxStatus.UNKNOWN]: { color: "gray", fillColor: "gray", fillOpacity: 0.7 },
};



export interface GeomanProps {
  mapFileUrl?: string
  mapFileDimensions?: Dimensions
  geojson: MapFeatureCollection
  spaces: SpaceModel[]; // Agregar aquí la lista de espacios
  ref?: any//React.MutableRefObject<GeomanHandler>
}


export interface GeomanHandler {
  geojson: MapFeatureCollection,
  getGeojson(): MapFeatureCollection,
  map: Map,
  setMapFileUrl: (fileUrl: string) => void
  setDefaulBounds: () => void
  //refreshMap: () => void
  getLayerByUUID: (uuid: string) => L.Layer | undefined
}


interface RenderFeatureProps {
  map: Map,
  feature: Feature<GeojsonPolygon | LineString>
  spaces: SpaceModel[];
  zoomLevel: any
}


const coordinatesToLatLng = (geometry: { type: 'Polygon' | 'LineString', coordinates: [number, number][] | [number, number][][] }): L.LatLngExpression[] => {
  let coordinates: [number, number][] = []
  if (geometry.type == 'LineString') {
    coordinates = geometry.coordinates as [number, number][]
  } else {
    coordinates = geometry.coordinates[0] as [number, number][]
  }
  const res: L.LatLngExpression[] = []
  for (let item of coordinates) {
    res.push({ lat: item[1], lng: item[0] })
  }
  return res
}

const RenderFeature = ({ feature, zoomLevel }: RenderFeatureProps) => {

  // const coordinates = feature.geometry.coordinates[0]//.filter(i=>(typeof i[0]=='number' && typeof i[1]=='number' ))
  const positions: L.LatLngExpression[] = coordinatesToLatLng(feature.geometry as any)
  const [hovered, setHovered] = React.useState(false); // Estado para manejar hover
  // Obtener los datos del box desde las propiedades del feature
  const itemName = (feature.properties as any)?.name;
  const meters = (feature.properties as any)?.meters;
  const ateneaUrl = (feature.properties as any)?.ateneaUrl;
  const isInteractive: boolean = (feature.properties as any).interactive == '1'
  const featureId: string = feature.id as string
  // console.debug('feature:',feature)


  const ateneaStatus = feature.properties?.ateneaStatus || AteneaBoxStatus.UNKNOWN;
  const statusLabel = (() => {
    switch (ateneaStatus) {
      case AteneaBoxStatus.AVAILABLE:
        return "Disponible";
      case AteneaBoxStatus.RENTED:
        return "Alquilado";
      case AteneaBoxStatus.MAINTENANCE:
        return "En mantenimiento";
      case AteneaBoxStatus.RESERVED:
        return "Reservado";
      case AteneaBoxStatus.UNKNOWN:
      default:
        return "-";
    }
  })();

  const price: number | null = feature.properties?.price
  const priceString: string = price ? (price.toFixed(2) + '€') : "-";
  const metersString: string = meters ? `${meters} m²` : '-'
  const basePathOptions = boxColors[ateneaStatus as AteneaBoxStatus] || boxColors[AteneaBoxStatus.UNKNOWN];

  let fillOpacity: number
  if (isInteractive) {
    fillOpacity = hovered ? 1 : basePathOptions.fillOpacity
  } else {
    fillOpacity = .3
  }


  // Estilos basados en el estado del box
  const pathOptions = {
    ...basePathOptions,
    fillOpacity: fillOpacity
  };


  // Calcular el tamaño de la fuente basado en el nivel de zoom
  const calculateFontSize = (zoom: number): string => {
    const baseFontSize = 5; // Tamaño base de fuente
    const multiplier = 3; // Multiplicador para escalar
    return `${baseFontSize + zoom * multiplier}px`;
  };

  // Calcula el centroide del box
  const centroid = React.useMemo(() => {
    return calculateCentroId(positions)
  }, [positions]);

  // console.debug('centroid:',centroid)


  const onLayerAdd = (ev: any) => {
    // console.debug('onLayerAdd',feature)
    ev.target.feature = feature
  }

  // Renderizar el polígono y sus elementos
  return (
    <Polygon
      pathOptions={{ ...pathOptions, stroke: false }}
      interactive={isInteractive}
      positions={positions}
      eventHandlers={{
        add: onLayerAdd,
        mouseover: () => setHovered(true), // Activar hover
        mouseout: () => setHovered(false), // Desactivar hover
        click: () => console.log(`Clicked on ${itemName}`),
      }}
    >

      {isInteractive &&
        (
          <>
            <Popup autoPan={true} className="custom-popup"  >
              <div style={{ padding: "0px", maxWidth: "200px" }}>
                <h3 style={{ margin: "0 0 10px", fontSize: "16px", fontWeight: "bold" }}>{itemName}</h3>
                <p style={{ margin: "5px 0", fontSize: "14px" }}>
                  <strong>Superficie:</strong> {metersString}
                </p>
                <p style={{ margin: "5px 0", fontSize: "14px" }}>
                  <strong>PVP:</strong> {priceString}
                </p>
                <p style={{ margin: "5px 0", fontSize: "14px" }}>
                  <strong>Estado:</strong> {statusLabel}
                </p>
                {ateneaUrl &&
                  <Button
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: "#007bff", color: "#fff", marginTop: "10px" }}
                    endIcon={<ArrowForward />}
                    href={ateneaUrl}
                    target='_blank'
                  >
                    Ver Detalles
                  </Button>
                }
              </div>
            </Popup>

            {centroid &&
              <Tooltip
                permanent
                direction="center"
                className={`custom-tooltip`} // Clase dinámica basada en el zoom
              >
                <div className={`box-label zoom-level-${zoomLevel}`}
                  style={{ fontSize: calculateFontSize(zoomLevel) }}
                > {/* Contenedor para organizar los textos */}
                  <span className="box-name">{itemName}</span>
                  {meters && <span className="box-size">{`${meters} m²`}</span>}
                </div>
              </Tooltip>
            }


          </>
        )
      }

    </Polygon>
  );
};


const GeomanWrapperFront = React.forwardRef((props: GeomanProps, mainRef) => {
  console.debug('GeomanWrapper init')

  const [zoomLevel, setZoomLevel] = React.useState<number>(2); // Zoom inicial
  const map: Map = useMap();
  // Escuchar cambios en el nivel de zoom
  // React.useEffect(() => {
  //   const handleZoom = () => {
  //     setZoomLevel(map.getZoom());
  //     console.log('zoom: ', map.getZoom());
  //   };
  //   map.on("zoomend", handleZoom);

  //   // Limpieza al desmontar el componente
  //   return () => {
  //     map.off("zoomend", handleZoom);
  //   };
  // }, [map]);



  //const [geojson, setGeojson] = React.useState<MapFeatureCollection>(props.geojson || emptyGeoJson)
  const geoRef = React.useRef<any>(null)
  const [mapFileUrl, setMapFileUrl] = React.useState<string | undefined>(props.mapFileUrl)
  const [imageOverLayBounds, setImageOverLayBounds] = React.useState<L.LatLngBoundsExpression>()
  const geojson = props.geojson || { type: "FeatureCollection", features: [] };

  const renderCount = useRef(0);
  useEffect(() => {
    renderCount.current += 1;
    console.log(`GeomanWrapperFront renderizado ${renderCount.current} veces`);
  });



  React.useEffect(() => {
    if (props.mapFileDimensions) {
      setBounds()
    }
  }, [map, props.mapFileUrl, props.mapFileDimensions]);

  const getLayerByUUID = (uuid: string): L.Layer | undefined => {
    if (!map) {
      return
    }
    const layers: L.Layer[] = []
    map.eachLayer(function (layer: L.Layer) {
      layers.push(layer)
    });
    for (let layer of layers) {
      const feature: any = (layer as any).feature
      if (feature) {
        const layerUUID: string = feature?.properties?.uuid
        if (layerUUID == uuid) {
          // console.log('layerUUID:', layerUUID);
          return layer
        }
      }
    }
    return undefined
  }

  const getGeojson = (): MapFeatureCollection => {
    const gjson = geoRef.current?.toGeoJSON() as MapFeatureCollection;
    console.log('geojson:', gjson);
    return gjson
  }

  const setBounds = () => {
    if (props.mapFileDimensions) {
      console.debug('setDefaulZoom')
      const bounds = getBounds(
        props.mapFileDimensions.width,
        props.mapFileDimensions.height
      );
      // const maxBounds = getBounds(width+500, height+500);
      // map.setMaxBounds(maxBounds);
      map.fitBounds(bounds, { animate: true });
    }
  }


  React.useImperativeHandle(mainRef, (): GeomanHandler => {
    return {
      geojson: geojson,
      map: map,
      setDefaulBounds: setBounds,
      setMapFileUrl: setMapFileUrl,
      getLayerByUUID: getLayerByUUID,
      getGeojson: getGeojson
    }
  }, [map, mapFileUrl, geojson, imageOverLayBounds])



  return (
    <FeatureGroup ref={geoRef} >
      {props.geojson.features.map((feature, idx) => (
        <RenderFeature key={idx} feature={feature} map={map} spaces={props.spaces} zoomLevel={zoomLevel} />
      ))}
    </FeatureGroup>
  );


});


export default GeomanWrapperFront


