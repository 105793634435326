import React from 'react'
import Parse from 'parse';
import { useSnackBar } from '../../providers/snackbar';
import { Button, FormControl, Grid, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/auth';



export interface LoginPageProps {

}

const LoginPage = (props: LoginPageProps) => {

    const [authData, setAuthData] = React.useState({ username: '', password: '' })
    // const signIn = useSignIn<IUserData>()
    const auth = useAuth()
    const snackbar = useSnackBar()
    const navigate = useNavigate();
    const currentUser = auth.state?.user
    const onSubmit = async (e: any) => {
        e.preventDefault()
        console.debug('onSubmit')
        let user: Parse.User
        try {
            await auth.login(authData.username, authData.password)
        } catch (err) {
            snackbar.showError({ message: String(err) })
            console.debug('login error:', err)
            return
        }
        navigate("/admin/places");
    }

    if (currentUser) {
        return <Navigate to={'/admin'} />
    }


    return (
        <form className="form" onSubmit={onSubmit} style={{ height: '95vh', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

            <div style={{ border: '1px gray solid', padding: "20px 10px", borderRadius: '10px' }}>
                <h3>Mapa interactivo</h3>
                <Grid container gap={2} >

                    <Grid item xs={12}>
                        <FormControl>
                            <TextField
                                type={"text"} label='Nombre de usuario' required value={authData.username} onChange={(e) => setAuthData({ ...authData, username: e.target.value })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <TextField
                                type={"password"} label='Password' required value={authData.password} onChange={(e) => setAuthData({ ...authData, password: e.target.value })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" color="primary" variant="contained" >
                            Log in
                        </Button>
                    </Grid>
                </Grid>
            </div>


        </form>

    )
}






export default LoginPage