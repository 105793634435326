import React from "react";
import "../App.css";

import {
    Outlet,
    Location,
    useLocation,
    useNavigate
} from "react-router-dom";


export default function RootEl() {
    const location: Location = useLocation()
    const navigate = useNavigate();
    React.useEffect(() => {
        if (location.pathname == '/') {
            // console.debug('navigate admin')
            navigate("/admin");
        }
    }, [location])
    return (
        <Outlet />
    )
}

