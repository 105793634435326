import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Box, Button, Divider, Grid, ListItemIcon, ListItemText } from "@mui/material";
import { LockOpenStatus, SpaceModel } from "../models/space";
// import InboxIcon from '@mui/icons-material/Inbox';
import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { FeatureItemModel } from '../models/featureItem';
import Grid2 from '@mui/material/Unstable_Grid2';

export type BoxListItemAction = 'assign' | 'unassign'

export interface BoxesListProps {
    items: SpaceModel[]
    featureItems: FeatureItemModel[]
    activeItemId?: string
    onItemClick?: (item: SpaceModel, action: BoxListItemAction) => void
    onItemMouseEnter?: (item: SpaceModel) => void
    onItemMouseLeave?: (item: SpaceModel) => void
}

export function BoxesList(props: BoxesListProps) {
    const boxes: SpaceModel[] = props.items
    const onItemMouseEnter = props.onItemMouseEnter
    const onItemMouseLeave = props.onItemMouseLeave

    const onAssignClick = (item: SpaceModel) => {
        if (props.onItemClick) {
            props.onItemClick(item, 'assign')
        }
    }
    const onUnassignClick = (item: SpaceModel) => {
        if (props.onItemClick) {
            props.onItemClick(item, 'unassign')
        }
    }

    const isItemActive = (item: SpaceModel): boolean => {
        return (props.activeItemId && props.activeItemId == item.id) ? true : false
    }

    const getFeatureItem = (item: SpaceModel): FeatureItemModel | undefined => {
        return (props.featureItems || []).find(i => i.space?.id == item.id)
    }

    const isItemAssigned = (item: SpaceModel): boolean => {
        const featureItem: FeatureItemModel | undefined = getFeatureItem(item)
        if (featureItem) {
            return true
        } else {
            return false
        }
    }

    const Item = (props: { item: SpaceModel }) => {
        const box: SpaceModel = props.item
        const isAssigned: boolean = isItemAssigned(props.item)
        const isActive: boolean = isItemActive(props.item)

        const getClassName = (): string => {
            const parts: string[] = []
            if (isAssigned) {
                parts.push('item-assigned')
            }

            if (isActive) {
                parts.push('item-selected')
            }
            return parts.join(" ")
        }

        const onMouseEnter = (ev: any) => {
            if (onItemMouseEnter) {
                onItemMouseEnter(box)
            }
            // if(!isAssigned){
            //     return
            // }
            // console.debug('onMouseEnter:',ev)
        }
        const onMouseLeave = (ev: any) => {
            if (onItemMouseLeave) {
                onItemMouseLeave(box)
            }
            // if(!isAssigned){
            //     return
            // }
            // console.debug('onMouseEnter:',ev)
        }



        const backgroundColor = (isAssigned) ? 'rgba(0,0,150,.1)' : ''

        return (
            <Grid onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} container spacing={2} style={{padding:30,backgroundColor: backgroundColor }} >
                <Grid item lg>
                    <h3 style={{ margin: 0, fontWeight: 400 }}>{box.name}</h3>
                    <div>
                        <small>{box.getAteneStatusString()}</small>
                    </div>
                </Grid>
                <Grid item>
                    {isAssigned &&
                        <Button size='small' variant="outlined" onClick={(ev) => { onUnassignClick(box) }}>Desasignar</Button>
                    }
                    {!isAssigned &&
                        <Button size='small' variant="outlined" onClick={(ev) => { onAssignClick(box) }}>Asignar</Button>
                    }
                </Grid>
            </Grid>
        )



    }


    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {boxes.map((box) => (
                <div key={box.id} style={{  }} >
                    <Item item={box} />
                    
                    <Divider style={{ marginBottom:20  }} />
                </div>
            ))}
        </Box>
    )
}
