import { useContext, createContext, useState, useEffect } from "react";
import { ViewMode } from "../constants";
import { AppSplashScreen } from "../components/SplashScreen";
import { Alert, Button, Grid } from "@mui/material";
import Parse from 'parse';
import { useNavigate } from "react-router-dom";
const AuthContext = createContext<AuthContextProps | undefined>(undefined);


export interface AuthData {
    user: Parse.User
    token: string
    isFirstLogin: boolean
}


export interface AuthContextProps {
    login: (username: string, password: string) => Promise<AuthData>,
    logout: () => Promise<void>
    state: AuthData | undefined
}

export interface AuthProviderProps {
    children: React.ReactNode
}

const AuthProvider = (props: AuthProviderProps) => {

    console.debug('AuthProvider')

    const [authData, setAuthData] = useState<AuthData | undefined>(undefined)
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.LOADING)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const navigate = useNavigate();

    const login = async (username: string, password: string): Promise<AuthData> => {
        let user: Parse.User
        try {
            user = await Parse.User.logIn(username, password)
        } catch (err) {
            // snackbar.showError({ message: String(err) })
            console.debug('login error:', err)
            return Promise.reject(err)
        }
        const token = user.getSessionToken()
        const data: AuthData = { user: user, token: token, isFirstLogin: true }
        setAuthData(data)
        localStorage.setItem('Parsetoken',token)
        return data
    }


    const logout = (): Promise<void> => {
        Parse.User.logOut().catch(err=>console.error('Parse logOut error:',err))    
        setAuthData(undefined)
        localStorage.removeItem('Parsetoken')
        // navigate("/admin/login")
        window.location.reload()
        return Promise.resolve()
    }

    const checkToken = async (): Promise<any> => {
        console.debug('checkToken')
        setViewMode(ViewMode.LOADING)
        const currentToken=localStorage.getItem('Parsetoken')
        if(!currentToken){
            setViewMode(ViewMode.CONTENT)
            return
        }
        let failed: boolean = false
        try {
            const currentUser: Parse.User | null = await Parse.User.currentAsync()
            const username = currentUser?.getUsername()
            console.debug("currentUser", username)
            if (!currentUser) {
                failed = true         
                return Promise.resolve(null)
            }
            const token: string = currentUser.getSessionToken()
            console.debug("token", token)
            if (!token) {
                logout()
                return Promise.resolve(null)
            }
            console.debug("fetchUser")
            await currentUser.fetch()
            console.debug("fetchUser success")
            const data: AuthData = { user: currentUser, token: token, isFirstLogin: false }
            setAuthData(data)
            return Promise.resolve(data)
        } catch (err: any) {
            const errorMessage = err.toString()
            setErrorMessage(errorMessage)
            console.debug("checkToken error", err)
            if (errorMessage.match(/Invalid session token/i)) {
                logout()
            } else {
                failed = true
                setViewMode(ViewMode.ERROR)
            }
            return Promise.resolve(null)
        } finally {
            if (!failed) {
                setViewMode(ViewMode.CONTENT)
                setErrorMessage('')
            }
        }
    }


    useEffect(() => {
        checkToken()
    }, [])


    const ErrorScreen = (): React.ReactElement => {

        const doLogout = () => {
            setViewMode(ViewMode.LOADING)
            logout().then(() => {
                setViewMode(ViewMode.CONTENT)
            }).catch(() => {
                setViewMode(ViewMode.ERROR)
            })
        }

        return (
            <Grid container style={{height:'100%'}} justifyContent={'center'}>
                <Grid item padding={10}>
                    <Alert severity="error">
                        Se ha producido un error al comprobar tus credenciales
                    </Alert>
                    <Grid container gap={2} justifyContent={'center'} marginTop={1}>
                        <Grid item>
                            <Button onClick={checkToken} type="button" variant="contained" >
                                Reintentar
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={doLogout} type="button" variant="contained" >
                                Cerrar sesion
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )

    }


    const value: AuthContextProps = {
        login: login,
        logout: logout,
        state: authData
    }

    if (viewMode == ViewMode.LOADING) {
        return <AppSplashScreen />
    }


    if (viewMode == ViewMode.ERROR) {
        return <ErrorScreen />
    }


    return <AuthContext.Provider value={value}>
        {props.children}
    </AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = (): AuthContextProps => {
    const ctx: AuthContextProps | undefined = useContext(AuthContext)
    if (ctx == undefined) {
        throw new Error("No se puede usar AuthContext sin provider")
    }
    return ctx
};

export const useCurrentUser = (): Parse.User => {
    const auth = useAuth()
    const user = auth.state?.user
    if (!user) {
        throw new Error("Sesion invalida")
    }
    return user
};