
import { AlertProps } from "@mui/material";
import React from "react";

export interface ShowAlertOptions {
    title?: string,
    message: string,
    severity?: 'success' | 'info' | 'warning' | 'error'
    cancelButtonLabel?: string
    alertOptions?: AlertProps
}

export interface FrontendCtxProps {
    isEmbedded: boolean
}

const FrontendCtx = React.createContext<FrontendCtxProps | undefined>(undefined);
export function useFrontEnd(): FrontendCtxProps {
    const ctx: FrontendCtxProps | undefined = React.useContext(FrontendCtx)
    if (!ctx) {
        throw new Error("No se puede utilizar FrontendCtxProps sin provider")
    }
    return ctx
}


export default function FrontendProvider({ children }: { children: React.ReactNode }) {
    
    const _isEmbedded:boolean = true//window.location.hostname.includes('embedded') || window.location.pathname.includes('embedded')
    const [isEmbedded, setIsEmbedded] = React.useState(_isEmbedded)
    const contextValue: FrontendCtxProps = { isEmbedded: isEmbedded }

    return (
        <FrontendCtx.Provider value={contextValue}>
            {children}
        </FrontendCtx.Provider>
    );
}