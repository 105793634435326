import {
  Navigate,
  Outlet
} from "react-router-dom";
import AuthProvider, { useAuth } from "../../providers/auth";
import { Button, ListItemButton, ListItemText, Menu, MenuItem, MenuList, Paper } from "@mui/material";
import React from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import { SnackBarProvider } from "../../providers/snackbar";
import DialogProvider from "../../providers/dialogs";
import AlertsProvider from "../../providers/alerts";
import { CurrentPlaceProvider } from "../../providers/currentPlace";
import { GlobalLoaderProvider } from "../../providers/globalLoader";
import FrontendProvider from "../../providers/frontend";



export function FrontendRootParent() {
  return <SnackBarProvider>
    <DialogProvider>
      <AlertsProvider>
        <AuthProvider>
          <FrontendProvider>
            <CurrentPlaceProvider>
              <GlobalLoaderProvider>
                <div style={{ padding: 10 }}>
                  <Outlet />
                </div>
              </GlobalLoaderProvider>
            </CurrentPlaceProvider>
          </FrontendProvider>
        </AuthProvider>
      </AlertsProvider>
    </DialogProvider>
  </SnackBarProvider>
}


export function FrontendRoot() {

  const auth = useAuth()
  const currentUser = auth.state?.user
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  return (
    <div>
      <Outlet />
    </div>
  )
}