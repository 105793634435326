import React, { ReactNode, useCallback, useEffect, useMemo, useState, useRef } from "react";
import Parse from 'parse';
import { Grid, Button, FormControl, MenuItem, Select, SelectChangeEvent, Box, Tab, Tabs } from "@mui/material";
import { Link, useLoaderData, useSearchParams } from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { PlaceModel } from "../../models/place";
import { ViewMode, MapFeatureCollection, Dimensions, AteneaBoxStatus } from "../../constants";
import { calculateCentroId, getBounds, urlToBase64 } from "../../utils";
import { SpaceModel } from "../../models/space";
import { FeatureItemModel, GetFeatureItemQueryParams } from "../../models/featureItem";
import { PlaceAreaModel } from "../../models/placeArea";
import * as L from 'leaflet';
import { ImageOverlay, MapContainer, MapContainerProps } from "react-leaflet";
import GeomanWrapperFront, { GeomanHandler } from "../../components/GeomanFront";
import BoxListSearchFront from "../../components/BoxListSearchFront";
import { features } from "process";
import { useFrontEnd } from "../../providers/frontend";

// Configuración de URLs
const BASE_API_URL = process.env.REACT_APP_PARSE_BASE_API_URL;
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

const defaultMapContainerOptions: MapContainerProps = {
  zoom: 3, // Zoom inicial
  center: [0, 0],
  minZoom: 1, // Permite alejar más
  maxZoom: 5, // Permite acercar más
  zoomSnap: 0.4, // Suavidad en el zoom
  zoomDelta: 0.4,
  crs: L.CRS.Simple as L.CRS,
};



export default function PlaceDetailsFrontPage() {
  const routeData: any = useLoaderData();
  const place: PlaceModel = routeData['place'];
  const defaultArea: PlaceAreaModel = routeData['area'] || routeData['areas'][0];
  const areas: PlaceAreaModel[] = routeData['areas'];
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedArea, setSelectedArea] = useState<PlaceAreaModel>(defaultArea);
  const [geojson, setGeojson] = useState<MapFeatureCollection>({ type: 'FeatureCollection', features: [] });
  const [mapFileUrl, setMapFileUrl] = useState<string | undefined>();
  const [mapFileDims, setMapFileDims] = useState<Dimensions>();
  const [mapContainerOptions, setMapContainerOptions] = useState<MapContainerProps>();
  const [imageOverLayBounds, setImageOverLayBounds] = useState<L.LatLngBoundsExpression | undefined>();
  const [ready, setReady] = useState<boolean>(false);
  const [frontendUrl, setFrontendUrl] = React.useState<string>('')
  const [spaces, setSpaces] = useState<SpaceModel[]>([]);
  const [selectedSpace, setSelectedSpace] = useState<SpaceModel | null>(null);
  const [menuExpanded, setMenuExpanded] = React.useState(true); // Estado para manejar el menú
  const [tabValue, setTabValue] = useState<string>(defaultArea?.id || "");// Estado del tab seleccionado
  const geomanWrapperRef = useRef<GeomanHandler>(null)
  const frontend = useFrontEnd()
  const isEmbedded = frontend.isEmbedded

  const flyToBox = (box: SpaceModel) => {
    if (!geomanWrapperRef.current) {
      return
    }
    const map = geomanWrapperRef.current?.map
    const feature: any = geojson.features.find(f => f.properties?.spaceId == box.id)
    if (feature) {
      const layer = geomanWrapperRef.current?.getLayerByUUID(feature.properties?.uuid as string)
      if (layer) {
        layer.openPopup();
        const bounds: L.LatLngBounds = (layer as any)._bounds
        const meters = box.meters
        let maxZoom: number = 1
        if (meters) {
          if (meters <= 1) {
            maxZoom = 5
          } else if (meters <= 2) {
            maxZoom = 4
          } else if (meters <= 3) {
            maxZoom = 3
          } else {
            maxZoom = 3
          }
        }
        maxZoom ||= 1
        map?.fitBounds(bounds, { maxZoom: maxZoom, animate: true, padding: [100, 100] });
      }
    }
  }
  
  const getSpaceByLayer = (layer: L.Layer): SpaceModel | null => {
    const feature = (layer as any).feature
    const spaceId = feature?.properties?.spaceId
    if (!spaceId) {
      return null
    }
    const space = spaces.find(s => s.id == spaceId)
    return space || null
  }
    
  const getLayerBySpace = (spaceId: string): L.Layer | null => {
    const feature: any = geojson.features.find(f => f.properties?.spaceId == spaceId)
    if(!feature){
      return null
    }
    const layer = geomanWrapperRef.current?.getLayerByUUID(feature.properties?.uuid as string)
    return layer || null
  }
  


  React.useEffect(() => {
    console.debug('mapContainerOptions:', mapContainerOptions)
  }, [mapContainerOptions])


  React.useEffect(() => {
    const areaIdParam = searchParams.get('areaId')
    if (areaIdParam && selectedArea && (areaIdParam != selectedArea.id)) {
      window.location.reload()
    }
  }, [searchParams, selectedArea])

  React.useEffect(() => {
    if (selectedSpace) { 
      const layer = getLayerBySpace(selectedSpace.id)
      layer?.openPopup();
      flyToBox(selectedSpace)
    }
  }, [selectedSpace, geomanWrapperRef, geojson, spaces])


  const onMapLayerClick = (ev: any) => {
    console.debug('onMapLayerClick:', ev)
    const space = getSpaceByLayer(ev.target)
    if (space) {
      setSelectedSpace(space)
    }
  }

 
  const onMapClick = (ev: any) => {
    console.debug('[main] onMapClick', ev)
    geomanWrapperRef.current?.setDefaulBounds()
    setSelectedSpace(null)
  }

  const onPopupClose = (ev: any) => {
    console.debug('[main] onPopupClose', ev)
    // geomanWrapperRef.current?.setDefaulBounds()
    setSelectedSpace(null)
  }

  const bodyClickListener = (ev: any) => {
    if (ev.target.className == 'leaflet-popup-close-button' || ev.target.parentElement?.className == 'leaflet-popup-close-button') {
      onPopupClose(ev)
    }
  }


  React.useEffect(() => {

    document.body.addEventListener('click', bodyClickListener);
    const map = geomanWrapperRef.current?.map
    if (map) {
      map.addEventListener('click', onMapClick)
      map.eachLayer((layer) => {
        layer.addEventListener('click', onMapLayerClick)
      })
    }

    return () => {
      document.body.removeEventListener('click', bodyClickListener);
      if (map) {
        map.removeEventListener('click', onMapClick)
        map.eachLayer((layer) => {
          layer.removeEventListener('click', onMapLayerClick)
        })
      }
    }


  }, [geomanWrapperRef.current?.map])


  //Carga la imagen de fondo
  React.useEffect(() => {
    if (mapFileUrl && mapFileDims) {
      const bounds = getBounds(mapFileDims.width, mapFileDims.height);
      setImageOverLayBounds(bounds);
    } else {
      setImageOverLayBounds(undefined);
    }
  }, [mapFileUrl, mapFileDims]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    setSearchParams({ areaId: newValue });
  };

  const loadBoxes = useCallback(async () => {
    console.debug("loadBoxes");
    try {
      const query = new Parse.Query(FeatureItemModel).equalTo('area', selectedArea).exists('space').include(['space.place']).limit(100000)
      const spaceIds: string[] = (await query.find()).filter(i => i.space).map(i => i.space.id)
      const spaces: SpaceModel[] = await SpaceModel.getAll({ objectIds: spaceIds })
      console.debug("loadBoxes success", spaces.length);
      setSpaces(spaces);
    } catch (err) {
      console.error("Error al cargar los boxes:", err);
    }
  }, [place.id]);

  const loadGeoJson = useCallback(async (selectedArea: PlaceAreaModel) => {
    try {
      console.debug("loadGeoJson");
      const response = await Parse.Cloud.run("placeArea:getGeoJson", { areaId: selectedArea.id });
      console.debug("loadGeoJson success:", response);
      setGeojson(response);
    } catch (err) {
      console.error("loadGeoJson error:", err);
    }
  }, []);

  const init = useCallback(async () => {
    console.debug("init");

    setMapContainerOptions({
      ...defaultMapContainerOptions,
      ...selectedArea?.mapContainerOptions
    });

    const mapUrl = `${BASE_API_URL}/mapplic/places/${place.id}/map_data.json`;
    setFrontendUrl(`${FRONTEND_URL}/?map=${encodeURI(mapUrl)}`);

    await loadBoxes();


    if (selectedArea) {

      const geojson = await Parse.Cloud.run("placeArea:getGeoJson", { areaId: selectedArea.id });

      await loadGeoJson(selectedArea); // Cargar el GeoJSON base  
      // await loadFeatureItems(); // Asegúrate de que no hay dependencia cíclica

      if (selectedArea.mapFile) {
        const fileUrl = selectedArea.mapFile.url();
        const mapFileUrl = await urlToBase64(fileUrl);
        setMapFileUrl(mapFileUrl);

        const mapFileWidth = selectedArea.get("mapFileWidth");
        const mapFileHeight = selectedArea.get("mapFileHeight");

        if (mapFileWidth && mapFileHeight) {
          setMapFileDims({ width: mapFileWidth, height: mapFileHeight });
        }
      }
    }

    // Solo marcar como listo si `geojson` contiene `ateneaStatus`
    if (geojson.features.length > 1 && geojson.features.every((f) => f.properties?.ateneaStatus !== undefined)) {
      console.log('geojson en el init:', geojson);
      setReady(true);
    }
  }, [selectedArea, place, loadBoxes, loadGeoJson]);

  // const onAreaChange = (event: SelectChangeEvent) => {
  //   const areaId = event.target.value;
  //   setSearchParams({ areaId });
  //   const newArea = areas.find((area) => area.id === areaId);
  //   if (newArea) {
  //     setSelectedArea(newArea);
  //   }
  // };

  useEffect(() => {
    if (!ready) {
      init();
    }
  }, [init])

  const hideTopMenu: boolean = isEmbedded && areas.length == 1


  return (
    <Grid container style={{ height: "95vh" }}>
      {/* Header */}
      <Grid
        item
        xs={12}
        sx={{ display: { xs: hideTopMenu ? 'none' : undefined } }}
        style={{
          padding: "10px 20px",
          borderBottom: "1px solid #ddd",
          backgroundColor: "#f9f9f9",
          marginBottom: 0
        }}
      >
        <Grid container spacing={2} alignItems="center" style={{ minHeight: 80 }}>

          {/* Nombre del Lugar */}
          <Grid item xs sx={{ display: { xs: isEmbedded ? 'none' : undefined } }}>
            <h3 style={{ margin: 0, fontSize: "1.5rem", color: "#333" }}>
              {place.name}
            </h3>
          </Grid>

          {/* Selector de Áreas */}
          <Grid container spacing={2} alignItems="center" sx={{ display: { xs: areas.length == 1 ? 'none' : undefined } }}>
            {/* Tabs en lugar del Select */}
            <Box
              sx={{
                width: "100%",
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center", // Centra horizontalmente
                alignItems: "center", // Alinea verticalmente
              }}
            >
              {/* Contenedor de Tabs */}
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Área de selección"
                centered // Centra las pestañas horizontalmente
                indicatorColor="primary"
                textColor="primary"
              >
                {areas.map((area) => (
                  <Tab key={area.id} label={area.name} value={area.id} />
                ))}
              </Tabs>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* Contenido */}
      <Grid item xs={12} style={{ display: "flex", height: hideTopMenu ? "100%" : "calc(100% - 60px)" }}>
        {/* Menú Izquierdo */}
        {/* Botón para expandir/colapsar */}
        <button
          className="toggle-button"
          onClick={() => setMenuExpanded(!menuExpanded)}
          style={{
            position: "absolute",
            top: "60%",
            left: menuExpanded ? "16%" : "1%", // Ajustar según el ancho del menú
            transform: "translateY(-50%)",
            zIndex: 1000, // Asegura que el botón siempre esté encima
          }}
        >
          {menuExpanded ? "←" : "→"}
        </button>

        {/* Menú lateral */}
        <div className={`menu-container ${menuExpanded ? "expanded" : "collapsed"}`}>
          <Grid
            item
            style={{
              borderRight: "1px solid #ddd",
              overflowY: "auto",
              backgroundColor: "#f7f7f7",
              height: "100%", // Asegurar que ocupe toda la altura
            }}
          >
            {/* Contenido del menú */}
            <BoxListSearchFront
              boxes={spaces} // Lista de SpaceModel
              activeItemId={selectedSpace?.id}
              onBoxSelect={(box) => setSelectedSpace(box)}
            />
          </Grid>
        </div>

        {/* Mapa */}
        <Grid item xs={12} style={{ position: "relative" }}>
          {mapFileUrl && geojson && (
            <div className="map-container" style={{ height: "100%", position: "relative" }}>
              <MapContainer {...mapContainerOptions} style={{ height: "100%", backgroundColor: 'rgb(242,242,242)' }}>
                {mapFileUrl && imageOverLayBounds && (
                  <ImageOverlay
                    url={mapFileUrl}
                    bounds={imageOverLayBounds}
                    opacity={1}
                    zIndex={10}
                    className="image-overlay"
                  />
                )}
                <GeomanWrapperFront
                  ref={geomanWrapperRef}
                  geojson={geojson}
                  mapFileUrl={mapFileUrl}
                  mapFileDimensions={mapFileDims}
                  spaces={spaces}
                />
              </MapContainer>

            </div>
          )}
        </Grid>

      </Grid>
    </Grid>
  );

}
